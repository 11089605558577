import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import AtIcon from '../../assets/icons/AtIcon';
import useInputValidate from '../../hooks/useInputValidate';
// Icons
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

export default function Input({
  id,
  name,
  placeholder,
  value,
  label,
  onChange,
  type,
  readOnly,
  style,
  maxLength,
  defaultValue,
  max,
  pattern,
  inputMode,
  showError,
  minLength,
  className,
  disabled,
  onFocus
}) {
  const [passwordView, setPasswordView] = useState(false);
  const { error, validate, setError } = useInputValidate(showError);

  const inputError = useMemo(() => {
    return !(showError === false || !error);
  }, [error]);

  return (
    <>
      <label htmlFor={id} className="text-grey mb-2 block text-12 font-dmsans_r capitalize">
        {label}
      </label>
      <div className="relative mb-2">
        <input
          id={id}
          name={name}
          type={type === 'password' ? (passwordView ? 'text' : 'password') : type}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onFocus={onFocus}
          defaultValue={defaultValue}
          maxLength={maxLength}
          minLength={minLength}
          inputMode={inputMode}
          max={max}
          pattern={pattern}
          data-testid={`test-${id}`}
          aria-labelledby={id}
          onChange={onChange}
          readOnly={readOnly || false}
          autoComplete="off"
          onBlur={() => validate({ name, value })}
          onKeyDown={() => setError('')}
          className={`${inputError ? 'border-red' : 'border-purple_transparent'} 
                                ${name === 'tag' && 'pl-[3.4rem]'} ${style === 'dropdown' && 'cursor-pointer'}
                                p-16 text-16 w-full outline-0 border font-dmsans_r font-normal hide_tap
                                rounded-lg focus:border-purple focus:border bg-purple_transparent ${className}`}
        />
        {name === 'tag' && (
          <div className="absolute bottom-1 left-1 p-16 cursor-pointer bg-purple_transparent rounded-lg">
            <AtIcon />
          </div>
        )}
        {style === 'dropdown' && (
          <div className="absolute top-[2%] right-[1%] h-[96%] flex items-center p-16 cursor-pointer bg-purple_transparent rounded-lg hide_tap">
            <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.9258 1L7.96261 7.96317L0.999439 1"
                stroke="#14142B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {type === 'password' && (
          <div
            data-testid="view"
            className="absolute bottom-1 right-1 p-16 cursor-pointer hide_tap rounded-lg"
            onClick={() => setPasswordView(!passwordView)}
          >
            {passwordView ? <AiOutlineEyeInvisible data-testid="hide" /> : <AiOutlineEye data-testid="show" />}
          </div>
        )}
      </div>
      {inputError ? <p className="font-dmsans_r text-red text-12 leading-[16px]">{error}</p> : ''}
    </>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  styles: PropTypes.string,
  style: PropTypes.string,
  maxLength: PropTypes.number,
  max: PropTypes.string,
  pattern: PropTypes.string,
  inputMode: PropTypes.string,
  showError: PropTypes.bool,
  minLength: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.any
};
