const base = '/user'

export const user = {
    change_transaction_pin: `${base}/reset-pin`,
    disable_user: `${base}/disable`,
    delete_user: `${base}/`,
    validate_bvn: `${base}/validate-bvn`,
    validate_id: `${base}/validate-id`,
    profile: `${base}/profile`,
    request_phone_change: `${base}/request-phone-change`,
    change_phone_number: `${base}/change-phone`,
    tiers: `${base}/tiers`,
    generate_2fa_token: `${base}/generate-2fa-token`,
    validate_2fa_token: `${base}/validate-2fa-token`,
    disable_2fa: `${base}/disable-2fa`,
    request_upload_url: `${base}/pre-signed-url`,
    transaction_history: `${base}/transaction-history`,
    transaction_analytics: `${base}/transaction-analytics`,
    get_referrals: `${base}/referral-trail`,
    get_schedules: `${base}/schedules`,
    get_banners: `/admin-settings/banners`,
    get_notifications: `/notification`,
    get_all_notifications: `/notification/unpaginated`,
    read_notification: `/notification`,
    read_all_notifications: `notification/read-all`,
    get_payment_request_count: `/notification/unread-payment-request`,
    read_all_payment_request: `/notification/read-all-payment-request`,
    verify_selfie: `${base}/verify-selfie`,
    verify_selfie_bvn: `${base}/verify-bvn-with-selfie-image`,
    delete_all_notifications: `notification`,
}