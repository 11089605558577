import { card } from '../../../services/endpoints'
import customAsyncThunk from '../../../utils/createThunk'

const createVirtualCard = customAsyncThunk('card/createVirtualCard', 'post', card.card)
const fundVirtualCard = customAsyncThunk('card/fundVirtualCard', 'post', card.fund)
const freezeVirtualCard = customAsyncThunk('card/freezeVirtualCard', 'patch', card.card)
const getVirtualCardToken = customAsyncThunk('card/getVirtualCardToken', 'get', card.card)
const getVirtualCardDetails = customAsyncThunk('card/getVirtualCardDetails', 'get', card.card)
const getCardTransactionHistory = customAsyncThunk('card/getCardTransactionHistory', 'get', card.card)
const getVirtualCards = customAsyncThunk('card/getVirtualCards', 'get', card.card)
const getVirtualCardBalance = customAsyncThunk('card/getVirtualCardBalance', 'get', card.get_virtual_accounts)
const getVirtualAccounts = customAsyncThunk('card/getVirtualAccounts', 'get', card.get_virtual_accounts)
const getCardAddress = customAsyncThunk('card/getCardAddress', 'get', card.get_card_address)

export {
    createVirtualCard,
    getVirtualAccounts,
    getVirtualCards,
    getVirtualCardDetails,
    fundVirtualCard,
    freezeVirtualCard,
    getCardTransactionHistory,
    getVirtualCardToken,
    getVirtualCardBalance,
    getCardAddress
}